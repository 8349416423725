import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const MenuItems = (props) => {

    const { parentMenu } = props;

    const location = useLocation();
    const postURL = location.pathname.split('/');
    const pathLength = Number(postURL.length)

    const [home, setHome] = useState(false)
	const [page, setPage] = useState(false)
	const [event, setEvent] = useState(false)
	const [course, setCourse] = useState(false)
	const [blog, setBlog] = useState(false)

	const openMobileMenu = menu => {

		if (menu === 'home') {
			setHome(!home)
			setPage(false)
			setEvent(false)
			setCourse(false)
			setBlog(false)
		}
		else if (menu === 'page') {
			setHome(false)
			setPage(!page)
			setEvent(false)
			setCourse(false)
			setBlog(false)
		}
		else if (menu === 'event') {
			setHome(false)
			setPage(false)
			setEvent(!event)
			setCourse(false)
			setBlog(false)
		}
		else if (menu === 'course') {
			setHome(false)
			setPage(false)
			setEvent(false)
			setCourse(!course)
			setBlog(false)
		}
		else if (menu === 'blog') {
			setHome(false)
			setPage(false)
			setEvent(false)
			setCourse(false)
			setBlog(!blog)
		}
	};

    return (
        <>

            <li className={location.hash === '#directions' ? 'menu-active' : ''}>
                <a href="/#directions">Yo'nalishlar</a>
            </li>
            <li className={location.hash === '#building' ? 'menu-active' : ''}>
                <a href="/#building">Texnikum binosi</a>
            </li>
            <li className={location.hash === '#licence' ? 'menu-active' : ''}>
                <a href="/#licence">Litsenziya</a>
            </li>
            <li className={location.hash === '#licence' ? 'menu-active' : ''}>
                <a
					href="https://shxtt.educon.uz"
					target="_blank"
					rel="noreferrer"
				>
					<span style={{ backgroundColor: "#3270FC", padding: "5px 10px", borderRadius: 5, color: "white"}}>
						Ariza topshirish
					</span>
				</a>
            </li>
        </>
    );
}

export default MenuItems;