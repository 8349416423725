import React from 'react';
import ErrorContent from '../components/Error';

const Error = () => {
    return (
        <>
            <ErrorContent />
        </> 
    );
}


export default Error;

