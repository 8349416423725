
import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/images/logos/footer-logo.png';

const FooterTwo = (props) => {
    const { footerLogo, footerClass, ctaSubtitle, ctaTitle, ctaBtn } = props;
    return (
        <>
            <footer id="react-footer" className={footerClass ? footerClass : 'react-footer react-footer-two pt---120'}>

                
                <div className="copyright">  
                    <div className="container">                  
                        <div className="react-copy-left">© 2022 <Link to="/">SHXTT.</Link> Barcha huquqlar himoyalangan</div>
                        <div className="react-copy-right">
                            <ul className="social-links">
                                <li className="follow">Bizga obuna bo'ling</li>
                                <li><Link to="#"><span aria-hidden="true" className="social_facebook"></span></Link></li>
                                <li><Link to="#"><span aria-hidden="true" className="social_instagram"></span></Link></li>
                                <li><Link to="#"><span aria-hidden="true" className="social_youtube"></span></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default FooterTwo;